import Quill from 'quill';
import { LogoCrop } from './_logo-crop';
import './polyfills/_event-submitter';

const STRIPE_PUBLIC_KEY = 'pk_live_51IOK6gEt6kvdakAErsAM2y9LiBh0nBtF8HjDFw286bi16J9Syoa8tB3jAcHuT0qdv2FZbuuUCuZrCVgeHRRZlLqw00M00DnKuG';
const STRIPE_TEST_KEY = 'pk_test_51IOK6gEt6kvdakAETbPt5uqO33aWJZtyH3NqrIlgJ3OuCnkEJ8Iyxu5LxnV9nJLXlDxAOsZylOpdV1fHe4oTjxQg00D8rfjLW7';

// logo constraints
const MAX_ALLOWED_LOGO_SIZE_IN_MB   = 5;
const MAX_ALLOWED_LOGO_WIDTH_IN_PX  = 360;
const MAX_ALLOWED_LOGO_HEIGHT_IN_PX = 180;

let quill = null;

// handler for validation errors from API
function showFieldError({ error_field, error_msg, scroll_to_error = true } = {}) {
  const errorField = document.getElementById(error_field);

  // mark the form-group as invlaid
  const group = errorField.closest('.form-group');
  group.classList.add('invalid');

  // create and append invalid-feedback element in form-group
  const invalidFeedback = document.createElement('div');
  invalidFeedback.className = 'invalid-feedback';
  invalidFeedback.innerText = error_msg;
  group.appendChild(invalidFeedback);

  // scroll input field input view
  if (scroll_to_error) {
    setTimeout(() => {
      group.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 100);
  }

  // if this field isn't the quill editor or the logo upload, bind change event listeners
  // to remove the invalid form-group styling (if it exists)
  // NOTE : quilljs and logo fields are handled manually.
  if (!group.classList.contains('editor') && !group.classList.contains('logo')) {
    function resetInvalidFeedback() {
      group.classList.remove('invalid');
      invalidFeedback.remove();
      errorField.removeEventListener('change', resetInvalidFeedback);
    }

    errorField.addEventListener('change', resetInvalidFeedback);
  }
}

// scroll to the first invalid form field
function scrollToFirstInvalidField() {
  const group = document.querySelector('.form-group.invalid');
  if (group) {
    setTimeout(() => {
      group.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 100);
  }
}

// clear single field error
function clearFieldError(error_field) {
  const errorField = document.getElementById(error_field);
  const group = errorField.closest('.form-group');
  group.classList.remove('invalid');

  const invalidFeedback = group.querySelector('.invalid-feedback');
  if (invalidFeedback) {
    invalidFeedback.remove();
  }
}

// clear any and all form errors (destroy invalid-feedback divs)
function clearAllFieldErrors() {
  const fields = document.querySelectorAll('.form-group.invalid');
  fields.forEach((field) => {
    const invalidFeedback = field.querySelector('.invalid-feedback');
    if (invalidFeedback) {
      invalidFeedback.remove();
    }
  });
}

function isQuillEmpty() {
  if ((quill.getContents()['ops'] || []).length !== 1) {
    return false;
  }
  return quill.getText().trim().length === 0;
}

// initialise form text editor
const jobDescriptionEditor = document.getElementById('job-description-editor');
if (jobDescriptionEditor) {
  quill = new Quill(jobDescriptionEditor, {
    theme: 'snow',
  });

  // remove form invalid-feedback if text changes
  quill.on('text-change', function() {
    const invalidFormGroup = jobDescriptionEditor.closest('.form-group.invalid');
    if (invalidFormGroup) {
      invalidFormGroup.classList.remove('invalid');

      const invalidFeedback = invalidFormGroup.querySelector('.invalid-feedback');
      if (invalidFeedback) {
        invalidFeedback.remove();
      }
    }
  });

  // because quill isn't an input field, it won't work with default label "for" stuff
  // we're manually adding a click handler to the label so we can force focus into quill editor
  const label = document.querySelector('label[for=job-description]');
  label.addEventListener('click', function(event) {
    event.preventDefault();
    quill.focus();
  });
}

// setup checkout handler
const checkout = document.getElementById('checkout');
if (checkout) {
  let key = window.location.hostname.toLowerCase() == 'one.welovechatter.com' ? STRIPE_PUBLIC_KEY : STRIPE_TEST_KEY;
  const stripe = new Stripe(key);

  // init logo crop
  const logoCrop = new LogoCrop({
    width: MAX_ALLOWED_LOGO_WIDTH_IN_PX,
    height: MAX_ALLOWED_LOGO_HEIGHT_IN_PX,
  });

  // only allow dates after today to be selected
  const startDate = document.getElementById('start-date');
  const today = new Date();
  today.setDate(today.getDate() + 1);
  startDate.setAttribute('min', today.toISOString().split("T")[0]);

  // listen for terms & conditions change event
  const terms = document.getElementById('terms-and-conditions');
  function toggleCheckoutButtons() {
    const buttons = checkout.querySelectorAll('#checkout-buttons button');
    buttons.forEach((button) => {
      if (terms.checked) {
        button.removeAttribute('disabled');
      } else {
        button.setAttribute('disabled', 'disabled');
      }
    });
  }
  terms.addEventListener('change', toggleCheckoutButtons);
  toggleCheckoutButtons();
  
  // listen for file change
  const logo = document.getElementById('logo');
  logo.addEventListener('change', function() {
    clearFieldError('logo');

    const [file] = logo.files;
    if (file) {
      logoCrop.reset();

      if (file.size > (MAX_ALLOWED_LOGO_SIZE_IN_MB * 1024 * 1024)) {
        logoCrop.resetInput();
        return showFieldError({
          error_field: 'logo',
          error_msg: 'The Logo file size must be no more than 5MB.',
        });
      }

      // load file blob into logo cropper
      logoCrop.load(file);
    }
  });

  // checkout submit handler
  checkout.addEventListener('submit', function(event) {
    event.preventDefault();

    // job description field is required!
    if (isQuillEmpty()) {
      return showFieldError({
        error_field: 'job-description',
        error_msg: 'This field is required.',
      });
    }

    // copy quill editor content into job-description input value
    document.getElementById('job-description').value = quill.container.firstChild.innerHTML;

    const data = new FormData(this);

    // update the logo with the (possibly) cropped version
    const { name } = data.get('logo');
    data.set('logo', logoCrop.getBlob(), name);

    // update submitter info from button
    const { paymentType } = event.submitter.dataset;
    data.append('payment-type', paymentType);

    const fetchOpts = {
      headers: {
        'Accept': 'application/json',
      },
      method: this.getAttribute('method'),
      body: data,
    };

    clearAllFieldErrors();

    fetch(this.getAttribute('action'), fetchOpts)
      .then(res => res.json())
      .then((data) => {
        if (data.status !== 'ok') {
          let err_string = 'Form validation failed!\n\n';
          for (const error in data.error_fields) {
            showFieldError({
              error_field: error,
              error_msg: data.error_fields[error],
              scroll_to_error: false,
            });

            err_string += `${error}: ${data.error_fields[error]}\n`;
          }

          scrollToFirstInvalidField();
          throw new Error(err_string);
        }

        // clean up logo preview blob
        // TODO : maybe not needed as it will be collected when we get redirected to stripe anyway?
        logoCrop.freeObjectURL();

        // forward to stripe checkout
        if (paymentType === 'stripe' && data.stripe_session_id) {
          return stripe.redirectToCheckout({ sessionId: data.stripe_session_id });
        }
        // forward to success page
        else if (paymentType === 'invoice' && data.redirect) {
          document.location = data.redirect;
        }
      })
      .then((result) => {
        console.log('Got result from stripe checkout:');
        console.log(result);

        if (result && result.error) {
          alert(result.error.message);
        }
      })
      .catch(err => console.error(err));
  });
}